import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {getCountryDeclensionWhereById} from "../../investment_offer/utils/get_country_declension_where";
import {SearchTab} from "../actions/fetch_search_all_action";

export const useSearchGtm = () => {
    const tabData = useSelector((state: IRPStore) => state.search.currentTabData);
    const tabCategory = tabData.currentTab === SearchTab.Holiday ? "Wakacyjne" : getCountryDeclensionWhereById(tabData.country);
    const viewType = useSelector((state: IRPStore) => state.viewType);
    const articleTitle = useSelector((state: IRPStore) => state.article.detail.article?.header);
    const offerListTitle = useSelector((state: IRPStore) => state.metaData.standardMetaData.title);
    let gtmLabel = offerListTitle.replace(" - rynekpierwotny.pl", "");
    if (viewType.current === "article" && articleTitle) {
        gtmLabel = articleTitle;
    }
    if (viewType.current === "homepage") {
        gtmLabel = "Strona główna";
    }

    return {
        gtmLabel: gtmLabel,
        gtmCategory: tabCategory
    };
};
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {getCountryDeclensionWhereById} from "../../investment_offer/utils/get_country_declension_where";
import {SearchTab} from "../actions/fetch_search_all_action";

export const useSearchGtm = () => {
    const tabData = useSelector((state: IRPStore) => state.search.currentTabData);
    const tabCategory = tabData.currentTab === SearchTab.Holiday ? "Wakacyjne" : getCountryDeclensionWhereById(tabData.country);
    const viewType = useSelector((state: IRPStore) => state.viewType);
    const articleTitle = useSelector((state: IRPStore) => state.article.detail.article?.header);
    const offerListTitle = useSelector((state: IRPStore) => state.metaData.standardMetaData.title);
    let gtmLabel = offerListTitle.replace(" - rynekpierwotny.pl", "");
    if (viewType.current === "article" && articleTitle) {
        gtmLabel = articleTitle;
    }
    if (viewType.current === "homepage") {
        gtmLabel = "Strona główna";
    }

    return {
        gtmLabel: gtmLabel,
        gtmCategory: tabCategory
    };
};
