import {Dispatch} from "redux";

import {appendQueryString, catch400, catchStalled, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {Country} from "../../region/types/Country";
import {createDebouncedAction} from "../../store/utils/create_debounced_action";

export interface IVendorListVendor {
    id: number;
    logo: {
        v_log_120x90: string;
    };
    name: string;
    headquarters: null | {
        type: number;
        address: string;
        region: {
            id: number;
            full_name: string;
        };
    };
    regions: string[];
    slug: string;
    stats: {
        offers_count_for_sale: number;
        properties_count_for_sale: number;
    };
}

export interface IVendorListResponse {
    count: number;
    page_size: number;
    results: IVendorListVendor[];
}

const FETCH_VENDOR_LIST = "offer/FETCH_VENDOR_LIST";
export const fetchVendorListTypes = createRequestActionTypes(FETCH_VENDOR_LIST);

const vendorListApiLink = apiV2ListLink.vendor.list(Scenario.VENDOR_LIST);

const fetchVendorListSimple = (name: string, country?: Country) => (dispatch: Dispatch) => {
    dispatch({type: fetchVendorListTypes.start});

    const url = appendQueryString(vendorListApiLink, {
        name,
        page_size: 10,
        has_properties_for_sale: "True",
        country
    });
    return getRequest({}, url)
        .then((response: IVendorListResponse) => {
            const result = {
                vendors: response.results
            };
            dispatch({type: fetchVendorListTypes.success, result});
            return response; // important for batched fetch
        })
        .catch(
            catch400((err) => {
                dispatch({type: fetchVendorListTypes.error, err});
            })
        )
        .catch(catchStalled((err) => console.warn("Response stalled. Error: ", err)));
};

export const {action: fetchVendorList, clear: stopFetchVendorList} = createDebouncedAction(fetchVendorListSimple, 500);
export const resetVendorList = () => ({type: fetchVendorListTypes.reset});
import {Dispatch} from "redux";

import {appendQueryString, catch400, catchStalled, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {Country} from "../../region/types/Country";
import {createDebouncedAction} from "../../store/utils/create_debounced_action";

export interface IVendorListVendor {
    id: number;
    logo: {
        v_log_120x90: string;
    };
    name: string;
    headquarters: null | {
        type: number;
        address: string;
        region: {
            id: number;
            full_name: string;
        };
    };
    regions: string[];
    slug: string;
    stats: {
        offers_count_for_sale: number;
        properties_count_for_sale: number;
    };
}

export interface IVendorListResponse {
    count: number;
    page_size: number;
    results: IVendorListVendor[];
}

const FETCH_VENDOR_LIST = "offer/FETCH_VENDOR_LIST";
export const fetchVendorListTypes = createRequestActionTypes(FETCH_VENDOR_LIST);

const vendorListApiLink = apiV2ListLink.vendor.list(Scenario.VENDOR_LIST);

const fetchVendorListSimple = (name: string, country?: Country) => (dispatch: Dispatch) => {
    dispatch({type: fetchVendorListTypes.start});

    const url = appendQueryString(vendorListApiLink, {
        name,
        page_size: 10,
        has_properties_for_sale: "True",
        country
    });
    return getRequest({}, url)
        .then((response: IVendorListResponse) => {
            const result = {
                vendors: response.results
            };
            dispatch({type: fetchVendorListTypes.success, result});
            return response; // important for batched fetch
        })
        .catch(
            catch400((err) => {
                dispatch({type: fetchVendorListTypes.error, err});
            })
        )
        .catch(catchStalled((err) => console.warn("Response stalled. Error: ", err)));
};

export const {action: fetchVendorList, clear: stopFetchVendorList} = createDebouncedAction(fetchVendorListSimple, 500);
export const resetVendorList = () => ({type: fetchVendorListTypes.reset});
