import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {Button} from "@pg-design/button-module";
import {Expandable} from "@pg-design/expandable-module";
import {calculateRemSize, pr, prettyScroll} from "@pg-design/helpers-css-strings";
import {ChevronDownIcon, ChevronUpIcon} from "@pg-design/icons-module";
import {getThemeBreakpoint} from "@pg-design/styles-strings";
import {IFormFieldProps} from "@pg-mono/form";
import {isEmpty} from "@pg-mono/nodash";
import {RequestState} from "@pg-mono/request-state";
import {useUserDevice} from "@pg-mono/user-device";

import {IRPStore} from "../../../app/rp_reducer";
import {Country} from "../../../region/types/Country";
import {ViewType} from "../../../view_type/ViewType";
import {SearchTab} from "../../actions/fetch_search_all_action";
import {IOfferQueryOffers} from "../../actions/fetch_search_offers_action";
import {AutocompletePrediction} from "../../actions/fetch_search_places_action";
import {IRegionListRegion} from "../../actions/fetch_search_regions_action";
import {IVendorListVendor} from "../../actions/fetch_search_vendors_action";
import {setTravelTimeData} from "../../actions/set_travel_time";
import {holidayLinks} from "../../constants/dropdown_links";
import {SEARCH_REGIONS_LIMIT} from "../../constants/search_regions";
import {IActiveDropdownItemStore} from "../../reducers/active_dropdown_item_reducer";
import {ResultsInfo} from "../atoms/ResultsInfo";
import {ISearchInputValue} from "../ISearchInputValue";
import {RegionTabs} from "../RegionTabs";
import {SearchLinksDropdownList} from "../SearchLinksDropdownList";
import {SuggestedRegions} from "../SuggestedRegions";
import {OffersTab} from "./tab_renderers/OffersTab";
import {PlacesTab} from "./tab_renderers/PlacesTab";
import {RegionsTab, RegionsTabLabelTypes} from "./tab_renderers/RegionsTab";
import {VendorsTab} from "./tab_renderers/VendorsTab";
import {ISearchAutocompleteFormValues} from "./SearchAutocomplete";

export interface IDropdownListOwnProps {
    dropdownIsOpen: boolean;
    search: ISearchInputValue;
    selectedTab: SearchTab;
    regions: IRegionListRegion[];
    requestStateObj: {
        fetchRegionsRequest: RequestState;
        fetchPlacesRequest: RequestState;
        fetchOffersRequest: RequestState;
        fetchVendorsRequest: RequestState;
    };
    activeItem: IActiveDropdownItemStore;
    vendors: IVendorListVendor[];
    places: AutocompletePrediction[];
    offers: IOfferQueryOffers[];
    onLinkClick: (option: ISearchInputValue, customCloseHandler?: (defaultCloseAction: () => void) => void) => void;
    removeRegionTag: (regionId: number | null) => void;
    onClose?: () => void;
    useTravelTime?: boolean;
    getFieldProps?: <TFieldName extends keyof ISearchAutocompleteFormValues>(
        fieldName: TFieldName
    ) => IFormFieldProps<TFieldName, ISearchAutocompleteFormValues[TFieldName]>;
    setTravelTimeData?: typeof setTravelTimeData;
    prevDistance?: number | string;
    isSearchButtonActive?: boolean;
}

export const SearchAutocompleteDropdown = (props: IDropdownListOwnProps) => {
    const searchTabCountry = useSelector((state: IRPStore) => state.search.currentTabData.country);
    const viewType = useSelector((state: IRPStore) => state.viewType);
    const isAbroadOffersView = viewType.current && [ViewType.OFFER_LIST_ABROAD, ViewType.OFFER_LIST_MAP_ABROAD].includes(viewType.current);
    const {isMobile} = useUserDevice();
    const scrollWrapperRef = useRef<HTMLDivElement>(null);
    const selectedRegionsWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollWrapperRef.current && props.search.tabType === SearchTab.Regions) {
            // only regions: if there is any selected regions, scroll direct to suggestion
            requestAnimationFrame(() => {
                if (scrollWrapperRef.current && selectedRegionsWrapperRef.current) {
                    scrollWrapperRef.current.scrollTo({
                        top: selectedRegionsWrapperRef.current.offsetHeight,
                        behavior: "smooth"
                    });
                }
            });
        }
    }, [props.search.tabType, props.regions]);

    const renderActiveTabResults = () => {
        const {selectedTab, useTravelTime} = props;
        if (useTravelTime) {
            return (
                <PlacesTab
                    onLinkClick={props.onLinkClick}
                    fetchPlacesRequest={props.requestStateObj.fetchPlacesRequest}
                    activeItem={props.activeItem}
                    places={props.places}
                    hideListLastSplitLine
                />
            );
        }
        switch (selectedTab) {
            case SearchTab.Regions:
                return (
                    <RegionsTab
                        regionsLabelType={RegionsTabLabelTypes.OFFERS}
                        regions={props.regions}
                        requestStateObj={props.requestStateObj}
                        activeItem={props.activeItem}
                        onLinkClick={props.onLinkClick}
                    />
                );
            case SearchTab.Offers:
                return (
                    <OffersTab
                        onLinkClick={props.onLinkClick}
                        activeItem={props.activeItem}
                        fetchPlacesRequest={props.requestStateObj.fetchPlacesRequest}
                        offers={props.offers}
                        fetchVendorsRequest={props.requestStateObj.fetchVendorsRequest}
                        fetchOffersRequest={props.requestStateObj.fetchOffersRequest}
                    />
                );
            case SearchTab.Vendors:
                return (
                    <VendorsTab
                        onLinkClick={props.onLinkClick}
                        activeItem={props.activeItem}
                        fetchPlacesRequest={props.requestStateObj.fetchPlacesRequest}
                        fetchVendorsRequest={props.requestStateObj.fetchVendorsRequest}
                        vendors={props.vendors}
                    />
                );
            case SearchTab.Places:
                return (
                    <PlacesTab
                        onLinkClick={props.onLinkClick}
                        fetchPlacesRequest={props.requestStateObj.fetchPlacesRequest}
                        activeItem={props.activeItem}
                        places={props.places}
                    />
                );
            default:
                return null;
        }
    };
    const renderDropdownContent = () => {
        if ([SearchTab.Holiday].some((tab) => tab === props.selectedTab)) {
            return <SearchLinksDropdownList links={holidayLinks} activeItemId={props.activeItem.id} />;
        } else if (props.search.tabType === SearchTab.Regions && props.search.regions.length >= SEARCH_REGIONS_LIMIT) {
            return <ResultsInfo title="Osiągnięto maksymalną liczbę regionów" text="Usuń jakiś region aby dodać nowy lub zacznij nowe szukanie." />;
        } else if (props.search.label && isEmpty(props.search.label) && isEmpty(props.places) && isEmpty(props.offers) && isEmpty(props.vendors)) {
            return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
        } else if (!props.search.label && !props.useTravelTime) {
            const isAbroad =
                isAbroadOffersView ||
                searchTabCountry === Country.SPAIN ||
                (props.search.tabType === SearchTab.Regions && props.search.regions[0]?.country === Country.SPAIN);
            return <SuggestedRegions activeItemId={props.activeItem.id} onLinkClick={props.onLinkClick} country={isAbroad ? Country.SPAIN : Country.POLAND} />;
        } else {
            return <ResultWrapper>{renderActiveTabResults()}</ResultWrapper>;
        }
    };

    return (
        <div css={scrollWrapperStyle} ref={scrollWrapperRef}>
            {!props.useTravelTime && (
                <div ref={selectedRegionsWrapperRef}>
                    {props.search.tabType === SearchTab.Regions && (props.search.regions.length > 2 || isMobile) ? (
                        <Expandable
                            isExpandedByDefault={false}
                            collapsedHeight={isMobile ? 0 : SELECTED_REGION_COLLAPSE_HEIGHT}
                            customCollapseButton={({isExpanded, toggleCollapse}) => (
                                <Button
                                    variant="none_secondary"
                                    size="x-small"
                                    type="button"
                                    iconRight={isExpanded ? ChevronUpIcon : ChevronDownIcon}
                                    onClick={toggleCollapse}
                                    css={regionExpandableButtonStyle}
                                >
                                    {isExpanded
                                        ? "Zwiń wybrane lokalizacje"
                                        : `Rozwiń wybrane lokalizacje ${props.search.tabType === SearchTab.Regions ? `(${props.search.regions.length})` : ""}`}
                                </Button>
                            )}
                            customShadowStyle={customExpandableShadowStyle}
                        >
                            <RegionTabs
                                search={props.search}
                                selectedTab={props.selectedTab}
                                removeRegionTag={props.removeRegionTag}
                                regionsLimit={SEARCH_REGIONS_LIMIT}
                            />
                        </Expandable>
                    ) : (
                        <RegionTabs
                            search={props.search}
                            selectedTab={props.selectedTab}
                            removeRegionTag={props.removeRegionTag}
                            regionsLimit={SEARCH_REGIONS_LIMIT}
                        />
                    )}
                </div>
            )}
            {renderDropdownContent()}
        </div>
    );
};

const SELECTED_REGION_COLLAPSE_HEIGHT = 110;

const scrollWrapperStyle = css`
    ${pr(1)};
    margin-right: -${calculateRemSize(1)};

    @media (min-width: ${getThemeBreakpoint().md}) {
        max-height: 29.8rem;
        overflow: auto;
    }
`;

const customExpandableShadowStyle = {
    background: "linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%)"
};

const regionExpandableButtonStyle = css`
    margin-top: -${calculateRemSize(1.5)};
    width: 100%;
`;

export const ResultWrapper = styled.div`
    overflow: auto;
    flex-shrink: 1;
    ${prettyScroll()};

    @media (min-width: ${getThemeBreakpoint().md}) {
        max-height: none;
    }
`;
import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {Button} from "@pg-design/button-module";
import {Expandable} from "@pg-design/expandable-module";
import {calculateRemSize, pr, prettyScroll} from "@pg-design/helpers-css-strings";
import {ChevronDownIcon, ChevronUpIcon} from "@pg-design/icons-module";
import {getThemeBreakpoint} from "@pg-design/styles-strings";
import {IFormFieldProps} from "@pg-mono/form";
import {isEmpty} from "@pg-mono/nodash";
import {RequestState} from "@pg-mono/request-state";
import {useUserDevice} from "@pg-mono/user-device";

import {IRPStore} from "../../../app/rp_reducer";
import {Country} from "../../../region/types/Country";
import {ViewType} from "../../../view_type/ViewType";
import {SearchTab} from "../../actions/fetch_search_all_action";
import {IOfferQueryOffers} from "../../actions/fetch_search_offers_action";
import {AutocompletePrediction} from "../../actions/fetch_search_places_action";
import {IRegionListRegion} from "../../actions/fetch_search_regions_action";
import {IVendorListVendor} from "../../actions/fetch_search_vendors_action";
import {setTravelTimeData} from "../../actions/set_travel_time";
import {holidayLinks} from "../../constants/dropdown_links";
import {SEARCH_REGIONS_LIMIT} from "../../constants/search_regions";
import {IActiveDropdownItemStore} from "../../reducers/active_dropdown_item_reducer";
import {ResultsInfo} from "../atoms/ResultsInfo";
import {ISearchInputValue} from "../ISearchInputValue";
import {RegionTabs} from "../RegionTabs";
import {SearchLinksDropdownList} from "../SearchLinksDropdownList";
import {SuggestedRegions} from "../SuggestedRegions";
import {OffersTab} from "./tab_renderers/OffersTab";
import {PlacesTab} from "./tab_renderers/PlacesTab";
import {RegionsTab, RegionsTabLabelTypes} from "./tab_renderers/RegionsTab";
import {VendorsTab} from "./tab_renderers/VendorsTab";
import {ISearchAutocompleteFormValues} from "./SearchAutocomplete";

export interface IDropdownListOwnProps {
    dropdownIsOpen: boolean;
    search: ISearchInputValue;
    selectedTab: SearchTab;
    regions: IRegionListRegion[];
    requestStateObj: {
        fetchRegionsRequest: RequestState;
        fetchPlacesRequest: RequestState;
        fetchOffersRequest: RequestState;
        fetchVendorsRequest: RequestState;
    };
    activeItem: IActiveDropdownItemStore;
    vendors: IVendorListVendor[];
    places: AutocompletePrediction[];
    offers: IOfferQueryOffers[];
    onLinkClick: (option: ISearchInputValue, customCloseHandler?: (defaultCloseAction: () => void) => void) => void;
    removeRegionTag: (regionId: number | null) => void;
    onClose?: () => void;
    useTravelTime?: boolean;
    getFieldProps?: <TFieldName extends keyof ISearchAutocompleteFormValues>(
        fieldName: TFieldName
    ) => IFormFieldProps<TFieldName, ISearchAutocompleteFormValues[TFieldName]>;
    setTravelTimeData?: typeof setTravelTimeData;
    prevDistance?: number | string;
    isSearchButtonActive?: boolean;
}

export const SearchAutocompleteDropdown = (props: IDropdownListOwnProps) => {
    const searchTabCountry = useSelector((state: IRPStore) => state.search.currentTabData.country);
    const viewType = useSelector((state: IRPStore) => state.viewType);
    const isAbroadOffersView = viewType.current && [ViewType.OFFER_LIST_ABROAD, ViewType.OFFER_LIST_MAP_ABROAD].includes(viewType.current);
    const {isMobile} = useUserDevice();
    const scrollWrapperRef = useRef<HTMLDivElement>(null);
    const selectedRegionsWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollWrapperRef.current && props.search.tabType === SearchTab.Regions) {
            // only regions: if there is any selected regions, scroll direct to suggestion
            requestAnimationFrame(() => {
                if (scrollWrapperRef.current && selectedRegionsWrapperRef.current) {
                    scrollWrapperRef.current.scrollTo({
                        top: selectedRegionsWrapperRef.current.offsetHeight,
                        behavior: "smooth"
                    });
                }
            });
        }
    }, [props.search.tabType, props.regions]);

    const renderActiveTabResults = () => {
        const {selectedTab, useTravelTime} = props;
        if (useTravelTime) {
            return (
                <PlacesTab
                    onLinkClick={props.onLinkClick}
                    fetchPlacesRequest={props.requestStateObj.fetchPlacesRequest}
                    activeItem={props.activeItem}
                    places={props.places}
                    hideListLastSplitLine
                />
            );
        }
        switch (selectedTab) {
            case SearchTab.Regions:
                return (
                    <RegionsTab
                        regionsLabelType={RegionsTabLabelTypes.OFFERS}
                        regions={props.regions}
                        requestStateObj={props.requestStateObj}
                        activeItem={props.activeItem}
                        onLinkClick={props.onLinkClick}
                    />
                );
            case SearchTab.Offers:
                return (
                    <OffersTab
                        onLinkClick={props.onLinkClick}
                        activeItem={props.activeItem}
                        fetchPlacesRequest={props.requestStateObj.fetchPlacesRequest}
                        offers={props.offers}
                        fetchVendorsRequest={props.requestStateObj.fetchVendorsRequest}
                        fetchOffersRequest={props.requestStateObj.fetchOffersRequest}
                    />
                );
            case SearchTab.Vendors:
                return (
                    <VendorsTab
                        onLinkClick={props.onLinkClick}
                        activeItem={props.activeItem}
                        fetchPlacesRequest={props.requestStateObj.fetchPlacesRequest}
                        fetchVendorsRequest={props.requestStateObj.fetchVendorsRequest}
                        vendors={props.vendors}
                    />
                );
            case SearchTab.Places:
                return (
                    <PlacesTab
                        onLinkClick={props.onLinkClick}
                        fetchPlacesRequest={props.requestStateObj.fetchPlacesRequest}
                        activeItem={props.activeItem}
                        places={props.places}
                    />
                );
            default:
                return null;
        }
    };
    const renderDropdownContent = () => {
        if ([SearchTab.Holiday].some((tab) => tab === props.selectedTab)) {
            return <SearchLinksDropdownList links={holidayLinks} activeItemId={props.activeItem.id} />;
        } else if (props.search.tabType === SearchTab.Regions && props.search.regions.length >= SEARCH_REGIONS_LIMIT) {
            return <ResultsInfo title="Osiągnięto maksymalną liczbę regionów" text="Usuń jakiś region aby dodać nowy lub zacznij nowe szukanie." />;
        } else if (props.search.label && isEmpty(props.search.label) && isEmpty(props.places) && isEmpty(props.offers) && isEmpty(props.vendors)) {
            return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
        } else if (!props.search.label && !props.useTravelTime) {
            const isAbroad =
                isAbroadOffersView ||
                searchTabCountry === Country.SPAIN ||
                (props.search.tabType === SearchTab.Regions && props.search.regions[0]?.country === Country.SPAIN);
            return <SuggestedRegions activeItemId={props.activeItem.id} onLinkClick={props.onLinkClick} country={isAbroad ? Country.SPAIN : Country.POLAND} />;
        } else {
            return <ResultWrapper>{renderActiveTabResults()}</ResultWrapper>;
        }
    };

    return (
        <div css={scrollWrapperStyle} ref={scrollWrapperRef}>
            {!props.useTravelTime && (
                <div ref={selectedRegionsWrapperRef}>
                    {props.search.tabType === SearchTab.Regions && (props.search.regions.length > 2 || isMobile) ? (
                        <Expandable
                            isExpandedByDefault={false}
                            collapsedHeight={isMobile ? 0 : SELECTED_REGION_COLLAPSE_HEIGHT}
                            customCollapseButton={({isExpanded, toggleCollapse}) => (
                                <Button
                                    variant="none_secondary"
                                    size="x-small"
                                    type="button"
                                    iconRight={isExpanded ? ChevronUpIcon : ChevronDownIcon}
                                    onClick={toggleCollapse}
                                    css={regionExpandableButtonStyle}
                                >
                                    {isExpanded
                                        ? "Zwiń wybrane lokalizacje"
                                        : `Rozwiń wybrane lokalizacje ${props.search.tabType === SearchTab.Regions ? `(${props.search.regions.length})` : ""}`}
                                </Button>
                            )}
                            customShadowStyle={customExpandableShadowStyle}
                        >
                            <RegionTabs
                                search={props.search}
                                selectedTab={props.selectedTab}
                                removeRegionTag={props.removeRegionTag}
                                regionsLimit={SEARCH_REGIONS_LIMIT}
                            />
                        </Expandable>
                    ) : (
                        <RegionTabs
                            search={props.search}
                            selectedTab={props.selectedTab}
                            removeRegionTag={props.removeRegionTag}
                            regionsLimit={SEARCH_REGIONS_LIMIT}
                        />
                    )}
                </div>
            )}
            {renderDropdownContent()}
        </div>
    );
};

const SELECTED_REGION_COLLAPSE_HEIGHT = 110;

const scrollWrapperStyle = css`
    ${pr(1)};
    margin-right: -${calculateRemSize(1)};

    @media (min-width: ${getThemeBreakpoint().md}) {
        max-height: 29.8rem;
        overflow: auto;
    }
`;

const customExpandableShadowStyle = {
    background: "linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%)"
};

const regionExpandableButtonStyle = css`
    margin-top: -${calculateRemSize(1.5)};
    width: 100%;
`;

export const ResultWrapper = styled.div`
    overflow: auto;
    flex-shrink: 1;
    ${prettyScroll()};

    @media (min-width: ${getThemeBreakpoint().md}) {
        max-height: none;
    }
`;
