// handles form values with special case for `search` field

import {QueryValues, toQueryValues} from "@pg-mono/form";

import {SearchTab} from "../actions/fetch_search_all_action";
import {ISearchInputRegion, ISearchInputValue} from "../components/ISearchInputValue";
import {searchFormFields} from "../constants/search_form_constants";

export const toQueryValuesWithSearch = <T>(formValues: Record<keyof T, unknown>): Record<string, string | number | number[]> => {
    // get valid query values
    const queryValues: QueryValues = toQueryValues(searchFormFields, formValues);
    const searchValue = (formValues as {search: ISearchInputValue})["search"];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {search, ...validQueryValues} = queryValues;

    switch (searchValue.tabType) {
        case SearchTab.Regions:
            return {
                region: searchValue.regions.map((region: ISearchInputRegion) => region.id),
                ...validQueryValues
            };

        case SearchTab.Offers:
            return {
                near_by_offer: searchValue.offer.id,
                ...validQueryValues
            };

        case SearchTab.Vendors:
            return {
                vendor: searchValue.vendor.id,
                ...validQueryValues
            };

        case SearchTab.Places:
            return {
                lat: searchValue.location.lat,
                lng: searchValue.location.lng,
                s: searchValue.place && searchValue.place.description,
                ...validQueryValues
            };

        default:
            return {
                ...validQueryValues
            };
    }
};
// handles form values with special case for `search` field

import {QueryValues, toQueryValues} from "@pg-mono/form";

import {SearchTab} from "../actions/fetch_search_all_action";
import {ISearchInputRegion, ISearchInputValue} from "../components/ISearchInputValue";
import {searchFormFields} from "../constants/search_form_constants";

export const toQueryValuesWithSearch = <T>(formValues: Record<keyof T, unknown>): Record<string, string | number | number[]> => {
    // get valid query values
    const queryValues: QueryValues = toQueryValues(searchFormFields, formValues);
    const searchValue = (formValues as {search: ISearchInputValue})["search"];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {search, ...validQueryValues} = queryValues;

    switch (searchValue.tabType) {
        case SearchTab.Regions:
            return {
                region: searchValue.regions.map((region: ISearchInputRegion) => region.id),
                ...validQueryValues
            };

        case SearchTab.Offers:
            return {
                near_by_offer: searchValue.offer.id,
                ...validQueryValues
            };

        case SearchTab.Vendors:
            return {
                vendor: searchValue.vendor.id,
                ...validQueryValues
            };

        case SearchTab.Places:
            return {
                lat: searchValue.location.lat,
                lng: searchValue.location.lng,
                s: searchValue.place && searchValue.place.description,
                ...validQueryValues
            };

        default:
            return {
                ...validQueryValues
            };
    }
};
