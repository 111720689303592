import React from "react";

import {CenterBox} from "@pg-design/grid-module";
import {Loader} from "@pg-design/loader-module";
import {RequestState} from "@pg-mono/request-state";
import {pluralize} from "@pg-mono/string-utils";

import {OfferType} from "../../../../offer/types/OfferType";
import {SearchTab} from "../../../actions/fetch_search_all_action";
import {IOfferQueryOffers} from "../../../actions/fetch_search_offers_action";
import {IActiveDropdownItemStore} from "../../../reducers/active_dropdown_item_reducer";
import {resultsList} from "../../atoms/atoms";
import {ResultsInfo} from "../../atoms/ResultsInfo";
import {ResultsListItem} from "../../atoms/ResultsListItem";
import {ISearchInputValue} from "../../ISearchInputValue";

interface IOwnProps {
    fetchOffersRequest: RequestState;
    fetchPlacesRequest: RequestState;
    fetchVendorsRequest: RequestState;
    activeItem: IActiveDropdownItemStore;
    offers: IOfferQueryOffers[];
    onLinkClick: (option: ISearchInputValue) => void;
}
interface IProps extends IOwnProps {}

export const OffersTab = (props: IProps) => {
    const {fetchOffersRequest, fetchPlacesRequest, offers, fetchVendorsRequest} = props;

    if (
        fetchOffersRequest === RequestState.Success &&
        fetchVendorsRequest !== RequestState.Waiting &&
        fetchPlacesRequest !== RequestState.Waiting &&
        offers.length === 0
    ) {
        return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
    }
    // display list/null also on fetching because we do not want loader to interrupt list view
    if (fetchOffersRequest === RequestState.Success || fetchOffersRequest === RequestState.Waiting) {
        if (offers.length === 0) {
            return (
                <CenterBox>
                    <Loader />
                </CenterBox>
            );
        }

        return (
            <ul css={resultsList}>
                {offers?.map((offer, idx) => {
                    const propertiesCount = offer.stats.properties_count_for_sale;
                    const propertiesFormat = (() => {
                        switch (offer.type) {
                            case OfferType.FLAT:
                                return pluralize(["mieszkanie", "mieszkania", "mieszkań"])(propertiesCount);
                            case OfferType.COMMERCIAL:
                                return pluralize(["lokal", "lokale", "lokali"])(propertiesCount);
                            case OfferType.HOUSE:
                                return pluralize(["dom", "domy", "domów"])(propertiesCount);
                            default:
                                return pluralize(["oferta", "oferty", "ofert"])(propertiesCount);
                        }
                    })();
                    const onOfferClick = () => {
                        props.onLinkClick({
                            label: offer.name,
                            tabType: SearchTab.Offers,
                            offer: offer,
                            type: offer.type,
                            vendor: offer.vendor
                        });
                    };

                    return (
                        <ResultsListItem
                            key={idx}
                            idx={idx}
                            subTitle={offer.region.full_name}
                            activeItemId={props.activeItem.id}
                            title={offer.name}
                            countLabel={`${propertiesCount} ${propertiesFormat}`}
                            onClick={onOfferClick}
                        />
                    );
                })}
            </ul>
        );
    }
    return null;
};
import React from "react";

import {CenterBox} from "@pg-design/grid-module";
import {Loader} from "@pg-design/loader-module";
import {RequestState} from "@pg-mono/request-state";
import {pluralize} from "@pg-mono/string-utils";

import {OfferType} from "../../../../offer/types/OfferType";
import {SearchTab} from "../../../actions/fetch_search_all_action";
import {IOfferQueryOffers} from "../../../actions/fetch_search_offers_action";
import {IActiveDropdownItemStore} from "../../../reducers/active_dropdown_item_reducer";
import {resultsList} from "../../atoms/atoms";
import {ResultsInfo} from "../../atoms/ResultsInfo";
import {ResultsListItem} from "../../atoms/ResultsListItem";
import {ISearchInputValue} from "../../ISearchInputValue";

interface IOwnProps {
    fetchOffersRequest: RequestState;
    fetchPlacesRequest: RequestState;
    fetchVendorsRequest: RequestState;
    activeItem: IActiveDropdownItemStore;
    offers: IOfferQueryOffers[];
    onLinkClick: (option: ISearchInputValue) => void;
}
interface IProps extends IOwnProps {}

export const OffersTab = (props: IProps) => {
    const {fetchOffersRequest, fetchPlacesRequest, offers, fetchVendorsRequest} = props;

    if (
        fetchOffersRequest === RequestState.Success &&
        fetchVendorsRequest !== RequestState.Waiting &&
        fetchPlacesRequest !== RequestState.Waiting &&
        offers.length === 0
    ) {
        return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
    }
    // display list/null also on fetching because we do not want loader to interrupt list view
    if (fetchOffersRequest === RequestState.Success || fetchOffersRequest === RequestState.Waiting) {
        if (offers.length === 0) {
            return (
                <CenterBox>
                    <Loader />
                </CenterBox>
            );
        }

        return (
            <ul css={resultsList}>
                {offers?.map((offer, idx) => {
                    const propertiesCount = offer.stats.properties_count_for_sale;
                    const propertiesFormat = (() => {
                        switch (offer.type) {
                            case OfferType.FLAT:
                                return pluralize(["mieszkanie", "mieszkania", "mieszkań"])(propertiesCount);
                            case OfferType.COMMERCIAL:
                                return pluralize(["lokal", "lokale", "lokali"])(propertiesCount);
                            case OfferType.HOUSE:
                                return pluralize(["dom", "domy", "domów"])(propertiesCount);
                            default:
                                return pluralize(["oferta", "oferty", "ofert"])(propertiesCount);
                        }
                    })();
                    const onOfferClick = () => {
                        props.onLinkClick({
                            label: offer.name,
                            tabType: SearchTab.Offers,
                            offer: offer,
                            type: offer.type,
                            vendor: offer.vendor
                        });
                    };

                    return (
                        <ResultsListItem
                            key={idx}
                            idx={idx}
                            subTitle={offer.region.full_name}
                            activeItemId={props.activeItem.id}
                            title={offer.name}
                            countLabel={`${propertiesCount} ${propertiesFormat}`}
                            onClick={onOfferClick}
                        />
                    );
                })}
            </ul>
        );
    }
    return null;
};
