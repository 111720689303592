/* eslint-disable @typescript-eslint/no-explicit-any */
import {FormFieldClassMap, FormFieldType, QueryValues} from "@pg-mono/form";
import {isEmpty} from "@pg-mono/nodash";

const safeParseInt = (value: string) => {
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? value : parsed;
};
const strEmpty = (value: any, defaultValue = "") => (value == null ? defaultValue : value);

const strToInt = (input: string | number | null | undefined): number | null | undefined => (typeof input === "string" ? parseInt(input, 10) : input);

export const fromQueryValues = <T>(types: FormFieldClassMap, queryValues: QueryValues): T => {
    return Object.entries(types).reduce((acc: any, [key, type]) => {
        switch (type) {
            case FormFieldType.Input:
                return {...acc, [key]: strEmpty(queryValues[key])};
            case FormFieldType.Checkbox:
            case FormFieldType.MultiCheckbox:
                let values;
                if (typeof queryValues[key] === "undefined") {
                    values = [];
                } else if (Array.isArray(queryValues[key])) {
                    values = queryValues[key].filter((queryValue: string) => queryValue.length > 0);
                } else {
                    values = queryValues[key].length > 0 ? [queryValues[key]] : [];
                }
                return {...acc, [key]: values.map((value: string) => safeParseInt(value))};
            case FormFieldType.Radio:
            case FormFieldType.SelectString:
                return {...acc, [key]: queryValues[key]};
            case FormFieldType.Select:
                return {...acc, [key]: safeParseInt(queryValues[key])};
            case FormFieldType.InputRange:
            case FormFieldType.SelectRange:
                const lower = queryValues[`${key}_0`];
                const upper = queryValues[`${key}_1`];
                return {
                    ...acc,
                    [key]: {
                        lower: strEmpty(lower),
                        upper: strEmpty(upper)
                    }
                };
            case FormFieldType.SelectRangeNumber:
                const intLower = strToInt(queryValues[`${key}_0`]);
                const intUpper = strToInt(queryValues[`${key}_1`]);
                return {
                    ...acc,
                    [key]: {
                        lower: strEmpty(intLower),
                        upper: strEmpty(intUpper)
                    }
                };
            case FormFieldType.SearchOfferType:
                if (queryValues[key] == null) {
                    return {...acc, [key]: []};
                }
                const stringArrayValues = Array.isArray(queryValues[key]) ? queryValues[key] : [queryValues[key]];
                return {
                    ...acc,
                    [key]: stringArrayValues.map((val: string) => parseInt(val)).filter((val: number) => !isNaN(val))
                };
            default:
                throw new Error(`fromQueryValues: not implemented for ${key}, formFieldType is ${type}`);
        }
    }, {});
};

/**
 * Additional helpers
 */
// skip values that are not properly parsed numbers
type ParsableQueryValue = string | number | (string | number)[];
export const parseListedQueryValuesToNumber = (queryValues: {[key: string]: ParsableQueryValue}, keyList: string[]) => {
    return Object.entries(queryValues).reduce((acc, [key, val]) => {
        if (!keyList.includes(key)) {
            return {...acc, [key]: val};
        }
        if (Array.isArray(val)) {
            const numberArray = val.reduce((acc2: any, v) => {
                const vInt = Number.isFinite(v) ? v : parseInt(v as string, 10); // parseInt handles not only strings
                return Number.isFinite(vInt) ? [...acc2, vInt] : acc2;
            }, []);
            return {...acc, [key]: numberArray};
        }
        const valInt = Number.isFinite(val) ? val : parseInt(val as string, 10); // parseInt handles not only strings
        return Number.isFinite(valInt) ? {...acc, [key]: valInt} : acc;
    }, {});
};

export const parseListedQueryValuesToString = (queryValues: {[key: string]: ParsableQueryValue}): Record<string, string | string[]> => {
    return Object.entries(queryValues).reduce((acc, [key, val]) => {
        if (Array.isArray(val)) {
            if (isEmpty(val)) {
                return acc;
            }
            const stringArray = val.reduce((acc2: any, v) => {
                return v != null ? [...acc2, v.toString()] : acc2;
            }, []);
            return {...acc, [key]: stringArray};
        }
        return val != null ? {...acc, [key]: val.toString()} : acc;
    }, {});
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import {FormFieldClassMap, FormFieldType, QueryValues} from "@pg-mono/form";
import {isEmpty} from "@pg-mono/nodash";

const safeParseInt = (value: string) => {
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? value : parsed;
};
const strEmpty = (value: any, defaultValue = "") => (value == null ? defaultValue : value);

const strToInt = (input: string | number | null | undefined): number | null | undefined => (typeof input === "string" ? parseInt(input, 10) : input);

export const fromQueryValues = <T>(types: FormFieldClassMap, queryValues: QueryValues): T => {
    return Object.entries(types).reduce((acc: any, [key, type]) => {
        switch (type) {
            case FormFieldType.Input:
                return {...acc, [key]: strEmpty(queryValues[key])};
            case FormFieldType.Checkbox:
            case FormFieldType.MultiCheckbox:
                let values;
                if (typeof queryValues[key] === "undefined") {
                    values = [];
                } else if (Array.isArray(queryValues[key])) {
                    values = queryValues[key].filter((queryValue: string) => queryValue.length > 0);
                } else {
                    values = queryValues[key].length > 0 ? [queryValues[key]] : [];
                }
                return {...acc, [key]: values.map((value: string) => safeParseInt(value))};
            case FormFieldType.Radio:
            case FormFieldType.SelectString:
                return {...acc, [key]: queryValues[key]};
            case FormFieldType.Select:
                return {...acc, [key]: safeParseInt(queryValues[key])};
            case FormFieldType.InputRange:
            case FormFieldType.SelectRange:
                const lower = queryValues[`${key}_0`];
                const upper = queryValues[`${key}_1`];
                return {
                    ...acc,
                    [key]: {
                        lower: strEmpty(lower),
                        upper: strEmpty(upper)
                    }
                };
            case FormFieldType.SelectRangeNumber:
                const intLower = strToInt(queryValues[`${key}_0`]);
                const intUpper = strToInt(queryValues[`${key}_1`]);
                return {
                    ...acc,
                    [key]: {
                        lower: strEmpty(intLower),
                        upper: strEmpty(intUpper)
                    }
                };
            case FormFieldType.SearchOfferType:
                if (queryValues[key] == null) {
                    return {...acc, [key]: []};
                }
                const stringArrayValues = Array.isArray(queryValues[key]) ? queryValues[key] : [queryValues[key]];
                return {
                    ...acc,
                    [key]: stringArrayValues.map((val: string) => parseInt(val)).filter((val: number) => !isNaN(val))
                };
            default:
                throw new Error(`fromQueryValues: not implemented for ${key}, formFieldType is ${type}`);
        }
    }, {});
};

/**
 * Additional helpers
 */
// skip values that are not properly parsed numbers
type ParsableQueryValue = string | number | (string | number)[];
export const parseListedQueryValuesToNumber = (queryValues: {[key: string]: ParsableQueryValue}, keyList: string[]) => {
    return Object.entries(queryValues).reduce((acc, [key, val]) => {
        if (!keyList.includes(key)) {
            return {...acc, [key]: val};
        }
        if (Array.isArray(val)) {
            const numberArray = val.reduce((acc2: any, v) => {
                const vInt = Number.isFinite(v) ? v : parseInt(v as string, 10); // parseInt handles not only strings
                return Number.isFinite(vInt) ? [...acc2, vInt] : acc2;
            }, []);
            return {...acc, [key]: numberArray};
        }
        const valInt = Number.isFinite(val) ? val : parseInt(val as string, 10); // parseInt handles not only strings
        return Number.isFinite(valInt) ? {...acc, [key]: valInt} : acc;
    }, {});
};

export const parseListedQueryValuesToString = (queryValues: {[key: string]: ParsableQueryValue}): Record<string, string | string[]> => {
    return Object.entries(queryValues).reduce((acc, [key, val]) => {
        if (Array.isArray(val)) {
            if (isEmpty(val)) {
                return acc;
            }
            const stringArray = val.reduce((acc2: any, v) => {
                return v != null ? [...acc2, v.toString()] : acc2;
            }, []);
            return {...acc, [key]: stringArray};
        }
        return val != null ? {...acc, [key]: val.toString()} : acc;
    }, {});
};
