// I do not quite get the logic of this function, it seems that we want to prevent invalid values, like strings or negative numbers
// However the idea to use `0` as default is strange. Maybe reasonable for lower bound, but definitely inappropriate for the upper bound.
const validationWhitelistedKeys = ["area_0", "area_1", "price_0", "price_1"];
export const validateQueryValues = (queryValuesRaw: Record<string, string | string[]>): Record<string, string | string[]> => {
    const absoluteNumber = (value: string | number) => Math.abs(+value) || 0; // Get rid of NaNs, default to 0.

    const getQueryValue = (value: string | string[], key: string) => {
        if (Array.isArray(value)) {
            return value;
        }
        if (validationWhitelistedKeys.includes(key)) {
            return absoluteNumber(value).toString();
        }
        return value;
    };

    return Object.entries(queryValuesRaw).reduce(
        (acc, [key, value]) => {
            acc[key] = getQueryValue(value, key);
            return acc;
        },
        {} as Record<string, string | string[]>
    );
};
// I do not quite get the logic of this function, it seems that we want to prevent invalid values, like strings or negative numbers
// However the idea to use `0` as default is strange. Maybe reasonable for lower bound, but definitely inappropriate for the upper bound.
const validationWhitelistedKeys = ["area_0", "area_1", "price_0", "price_1"];
export const validateQueryValues = (queryValuesRaw: Record<string, string | string[]>): Record<string, string | string[]> => {
    const absoluteNumber = (value: string | number) => Math.abs(+value) || 0; // Get rid of NaNs, default to 0.

    const getQueryValue = (value: string | string[], key: string) => {
        if (Array.isArray(value)) {
            return value;
        }
        if (validationWhitelistedKeys.includes(key)) {
            return absoluteNumber(value).toString();
        }
        return value;
    };

    return Object.entries(queryValuesRaw).reduce(
        (acc, [key, value]) => {
            acc[key] = getQueryValue(value, key);
            return acc;
        },
        {} as Record<string, string | string[]>
    );
};
