import React from "react";
import {css} from "@emotion/react";

import {CenterBox} from "@pg-design/grid-module";
import {Loader} from "@pg-design/loader-module";
import {loadGoogleMapsApi} from "@pg-mono/google-api";
import {RequestState} from "@pg-mono/request-state";

import {SearchTab} from "../../../actions/fetch_search_all_action";
import {AutocompletePrediction} from "../../../actions/fetch_search_places_action";
import {IActiveDropdownItemStore} from "../../../reducers/active_dropdown_item_reducer";
import {googlePlacesDivId} from "../../../utils/search_constants";
import {resultsList} from "../../atoms/atoms";
import {ResultsInfo} from "../../atoms/ResultsInfo";
import {ResultsListItem} from "../../atoms/ResultsListItem";
import {ISearchInputValue} from "../../ISearchInputValue";

interface IOwnProps {
    fetchPlacesRequest: RequestState;
    places: AutocompletePrediction[];
    activeItem: IActiveDropdownItemStore;
    onLinkClick: (option: ISearchInputValue) => void;
    hideListLastSplitLine?: boolean;
}

interface IProps extends IOwnProps {}

export const PlacesTab = (props: IProps) => {
    const {fetchPlacesRequest, places} = props;
    if ((fetchPlacesRequest === RequestState.Success && places.length === 0) || fetchPlacesRequest === RequestState.None) {
        return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
    }

    const renderPlaceItem = (place: AutocompletePrediction, idx: number) => {
        const onPlaceClick = async () => {
            await loadGoogleMapsApi(["places"]);

            if (!(google && google.maps && google.maps.places && google.maps.places.PlacesService)) {
                return console.error("PlacesTabItem: google API is not defined");
            }

            const {place_id: placeId} = place;
            const _googlePlacesTabResults = document.getElementById(googlePlacesDivId);
            const service = new google.maps.places.PlacesService(_googlePlacesTabResults as HTMLDivElement);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            service.getDetails({placeId}, (placeDetail: any) => {
                //TODO: Google maps typings. For now we are copy pasting interfaces for yarn has problems with handling typings for global vars.
                const lat = placeDetail.geometry.location.lat();
                const lng = placeDetail.geometry.location.lng();
                props.onLinkClick({
                    label: place.description,
                    tabType: SearchTab.Places,
                    place: place,
                    location: {lat, lng}
                });
            });
        };

        return <ResultsListItem key={idx} idx={idx} activeItemId={props.activeItem.id} title={place.description} onClick={onPlaceClick} />;
    };

    if (fetchPlacesRequest === RequestState.Success || fetchPlacesRequest === RequestState.Waiting) {
        if (places.length === 0) {
            return (
                <CenterBox>
                    <Loader />
                </CenterBox>
            );
        }

        return <ul css={[resultsList, props.hideListLastSplitLine && hideLastSplitLine]}>{props.places.map(renderPlaceItem)}</ul>;
    }
    return null;
};

const hideLastSplitLine = css`
    & > li:last-child > div {
        border-bottom: unset;
    }
`;
import React from "react";
import {css} from "@emotion/react";

import {CenterBox} from "@pg-design/grid-module";
import {Loader} from "@pg-design/loader-module";
import {loadGoogleMapsApi} from "@pg-mono/google-api";
import {RequestState} from "@pg-mono/request-state";

import {SearchTab} from "../../../actions/fetch_search_all_action";
import {AutocompletePrediction} from "../../../actions/fetch_search_places_action";
import {IActiveDropdownItemStore} from "../../../reducers/active_dropdown_item_reducer";
import {googlePlacesDivId} from "../../../utils/search_constants";
import {resultsList} from "../../atoms/atoms";
import {ResultsInfo} from "../../atoms/ResultsInfo";
import {ResultsListItem} from "../../atoms/ResultsListItem";
import {ISearchInputValue} from "../../ISearchInputValue";

interface IOwnProps {
    fetchPlacesRequest: RequestState;
    places: AutocompletePrediction[];
    activeItem: IActiveDropdownItemStore;
    onLinkClick: (option: ISearchInputValue) => void;
    hideListLastSplitLine?: boolean;
}

interface IProps extends IOwnProps {}

export const PlacesTab = (props: IProps) => {
    const {fetchPlacesRequest, places} = props;
    if ((fetchPlacesRequest === RequestState.Success && places.length === 0) || fetchPlacesRequest === RequestState.None) {
        return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
    }

    const renderPlaceItem = (place: AutocompletePrediction, idx: number) => {
        const onPlaceClick = async () => {
            await loadGoogleMapsApi(["places"]);

            if (!(google && google.maps && google.maps.places && google.maps.places.PlacesService)) {
                return console.error("PlacesTabItem: google API is not defined");
            }

            const {place_id: placeId} = place;
            const _googlePlacesTabResults = document.getElementById(googlePlacesDivId);
            const service = new google.maps.places.PlacesService(_googlePlacesTabResults as HTMLDivElement);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            service.getDetails({placeId}, (placeDetail: any) => {
                //TODO: Google maps typings. For now we are copy pasting interfaces for yarn has problems with handling typings for global vars.
                const lat = placeDetail.geometry.location.lat();
                const lng = placeDetail.geometry.location.lng();
                props.onLinkClick({
                    label: place.description,
                    tabType: SearchTab.Places,
                    place: place,
                    location: {lat, lng}
                });
            });
        };

        return <ResultsListItem key={idx} idx={idx} activeItemId={props.activeItem.id} title={place.description} onClick={onPlaceClick} />;
    };

    if (fetchPlacesRequest === RequestState.Success || fetchPlacesRequest === RequestState.Waiting) {
        if (places.length === 0) {
            return (
                <CenterBox>
                    <Loader />
                </CenterBox>
            );
        }

        return <ul css={[resultsList, props.hideListLastSplitLine && hideLastSplitLine]}>{props.places.map(renderPlaceItem)}</ul>;
    }
    return null;
};

const hideLastSplitLine = css`
    & > li:last-child > div {
        border-bottom: unset;
    }
`;
