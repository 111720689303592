import {Country} from "../../region/types/Country";
import {isSpainInvestmentCategory} from "../../search/hooks/use_search_param_abroad_country";
import {SpainInvestmentOfferSlug} from "../constants/investment_offer_constants";

export const getCountryDeclensionWhere = (country: SpainInvestmentOfferSlug) => {
    if (isSpainInvestmentCategory(country)) {
        return "w Hiszpanii";
    }
    return "";
};

export const getCountryDeclensionWhereById = (countryId?: Country | null) => {
    if (!countryId) {
        return "w Polsce";
    }

    switch (countryId) {
        case Country.SPAIN:
            return "w Hiszpanii";
        default:
            return "w Polsce";
    }
};
import {Country} from "../../region/types/Country";
import {isSpainInvestmentCategory} from "../../search/hooks/use_search_param_abroad_country";
import {SpainInvestmentOfferSlug} from "../constants/investment_offer_constants";

export const getCountryDeclensionWhere = (country: SpainInvestmentOfferSlug) => {
    if (isSpainInvestmentCategory(country)) {
        return "w Hiszpanii";
    }
    return "";
};

export const getCountryDeclensionWhereById = (countryId?: Country | null) => {
    if (!countryId) {
        return "w Polsce";
    }

    switch (countryId) {
        case Country.SPAIN:
            return "w Hiszpanii";
        default:
            return "w Polsce";
    }
};
